import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const FileUploadComponent = () => {
  return (
    <div className="form-group">
      <input
        type="file"
        className="form-control-file form-control-lg" // Use form-control-lg for larger input
        // Your file handling bindings here
      />
    </div>
  );
};

export default FileUploadComponent;
