import React from 'react';
import ChatComponent from './components/ChatComponent';
import FileUploadComponent from './components/FileUploadComponent';
import './App.css'; // Assuming you have an App.css for styling
// Add this import at the top of the file
import 'bootstrap/dist/css/bootstrap.min.css';
//let us import logo from public folder
const logo = `${process.env.PUBLIC_URL}/logo.png`;

// Use the logo variable in your component
function App() {


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      <h1 className="indapoint-bot-heading">Project Estimation Bot</h1>

      </header>
      <div className="App">
      <div className="content">
        <ChatComponent />
      </div>
    </div>
  );
    </div>
  );
}

export default App;
