import React, { useState, useEffect, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CircularProgress from '@mui/material/CircularProgress';

//pronit dev evn being used
const apiUrl = process.env.REACT_APP_API_BASE_URL;
console.log('API URL:', apiUrl);

const ChatComponent = () => {
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId] = useState('');
  const [fileId, setFileId] = useState('');
  const bottomOfChat = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    bottomOfChat.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  // Function to check if a message contains a link
  const containsLink = (text) => {
    const urlPattern = /https?:\/\/[^\s]+/g; // Simple pattern to match URLs
    return urlPattern.test(text);
  };

  const appendMessage = (text, type, status = 'Success') => {
    const isLink = containsLink(text);
    setChatMessages(prev => [...prev, { text, type, status, isLink }]);
  };

  const sendMessage = async () => {
    if (!message && !file && !fileId) {
      alert("Please enter a message or select a file.");
      return;
    }

    setIsLoading(true);
    
    const formData = new FormData();
    formData.append('message', message);
    if (file) formData.append('file', file);
    if (threadId) formData.append('thread_id', threadId);
    if (fileId) formData.append('file_id', fileId);

    try {
      const response = await fetch(`${apiUrl}/callbot`, {
        method: 'POST',
        body: formData,
      });

      const responseData = await response.json();
      if (response.ok) {
        appendMessage(message, 'sent');
        if (responseData.message) {
          appendMessage(responseData.message, 'response');
        }
        setThreadId(responseData.thread_id || '');
        setFileId(responseData.file_id || '');
      } else {
        console.error('Error from server:', responseData);
        appendMessage('Failed to send message', 'response', 'Error');
      }
    } catch (error) {
      console.error("Error sending message:", error);
      appendMessage("Failed to send message", 'response', 'Error');
    } finally {
      setIsLoading(false);
      setMessage('');
      setFile(null);
      if (fileInputRef.current) fileInputRef.current.value = '';
    }
  };

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <div className="chat-container">
      <div className="chat-logs">
        {chatMessages.map((msg, index) => (
          <div key={index} className={`message ${msg.type}`}>
            {msg.isLink ? (
              <a href={msg.text} target="_blank" rel="noopener noreferrer">{msg.text}</a>
            ) : (
              msg.text
            )}
          </div>
        ))}
        <div ref={bottomOfChat} />
      </div>
      <div className="input-area">
        <IconButton color="primary" aria-label="upload picture" component="label" disabled={isLoading}>
          <input type="file" hidden onChange={handleFileUpload} ref={fileInputRef} />
          {isLoading ? <CircularProgress size={24} /> : <AttachFileIcon />}
        </IconButton>
        <input 
          type="text" 
          className="message-input" 
          placeholder="Type a message" 
          onChange={(e) => setMessage(e.target.value)} 
          value={message} 
          disabled={isLoading}
        />
        <IconButton 
          onClick={sendMessage} 
          color="primary" 
          aria-label="send message" 
          disabled={isLoading}
        >
          <SendIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default ChatComponent;
